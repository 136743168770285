import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import {
  Body,
  Container,
  Header,
  HeaderSection,
  RightColumn,
  StyledList,
  StyledListItem,
  Subheader,
  FloatingTreatmentButton,
} from './SupplierCtaModule.style';

import { useTheme } from 'styled-components';
import { SupplierCTAImage } from './SupplierCtaImage';
import { withImpressionTracking } from 'analytics';
import { v4 as uuid } from 'uuid';
import {
  trackModuleView,
  trackMobileStickyJumpLinkClicked,
  WINDOW_PARAM,
  SUPPLIER_CTA_ANCHOR,
} from './analytics';

import ArrowDownwardRounded from '@mui/icons-material/ArrowDownwardRounded';
import useRefInView from 'hooks/useRefInView';
import useLanguage from 'hooks/useLanguage';
import SupplierCtaButton from './CtaButton';
import ScrollableAnchorWrapper from 'components/ScrollableAnchorWrapper';
import usePage from 'hooks/usePage';
import { Base } from 'styled/components/base';
import { ICONS } from './constants';
import useAdPlacements, { AD_TYPES } from 'hooks/useAdPlacements';
interface ISupplierCtaModuleProps {
  module: ISupplierCtaModule;
  ctaOnly?: boolean;
  applyBlogPostBodyWidthOffset?: boolean;
}

const SupplierCtaModule = ({
  module,
  ctaOnly,
  applyBlogPostBodyWidthOffset,
}: ISupplierCtaModuleProps) => {
  const theme = useTheme();
  const moduleId = useMemo(() => uuid(), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const isInViewport = useRefInView(containerRef, { freezeOnceVisible: false });

  const ads = useAdPlacements();

  const language = useLanguage();
  const page = usePage();

  const isLastSupplierCtaModule = useMemo(
    () =>
      page.article?.content.bodyModules.findLast(
        (module) => module?.type === 'supplierCtaModule',
      )?.id === module.id,
    [module.id, page.article?.content.bodyModules],
  );

  useEffect(() => {
    if (isLastSupplierCtaModule) {
      window[WINDOW_PARAM] = moduleId;
    }
  }, [isLastSupplierCtaModule, moduleId]);

  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking(Base, () => {
        trackModuleView(moduleId);
      }),
    [moduleId],
  );

  const onJumpLinkClick = useCallback(() => {
    trackMobileStickyJumpLinkClicked(moduleId);
  }, [moduleId]);

  if (ctaOnly) {
    return (
      <SupplierCtaButton
        ctaOnly
        label={language.t('SupplierCtaModule.getTreatedToday')}
        module={module}
        scrollToModule
        moduleId={moduleId}
      />
    );
  }

  const arrowDirection =
    containerRef.current &&
    containerRef.current?.getBoundingClientRect().top <= 0
      ? 'UP'
      : 'DOWN';

  const videoAdHeight = ads[AD_TYPES.CORNER_VIDEO]?.clientHeight || 0;
  const bottomRailAdHeight = ads[AD_TYPES.BOTTOM_RAIL]?.clientHeight || 0;
  const adHeight = videoAdHeight + bottomRailAdHeight;

  return (
    <ScrollableAnchorWrapper
      section={{
        anchor: isLastSupplierCtaModule ? SUPPLIER_CTA_ANCHOR : '',
        id: moduleId,
        label: 'SupplierCTAModule',
      }}
    >
      <div
        ref={containerRef}
        className={applyBlogPostBodyWidthOffset ? 'px1_5 md:px0' : undefined}
      >
        <TrackingContainer>
          <Container addMargin={!applyBlogPostBodyWidthOffset}>
            <SupplierCTAImage image={module.image} />
            <RightColumn>
              <HeaderSection>
                <div>
                  <Header>{module.header}</Header>
                  <Subheader>{module.subheader}</Subheader>
                </div>
                <SupplierCTAImage image={module.image} />
              </HeaderSection>
              <Body>
                <StyledList>
                  {module.bulletPoints.map((bp, i) => {
                    const Icon = ICONS.find(
                      (icon) => bp.icon === icon.name,
                    )?.component;
                    return (
                      <StyledListItem key={bp.text}>
                        <span>
                          {Icon && (
                            <Icon
                              backgroundColor={theme.palette.common.white}
                              color={theme.palette.orange[40]}
                            >
                              {i + 1}
                            </Icon>
                          )}
                        </span>
                        <span>{bp.text}</span>
                      </StyledListItem>
                    );
                  })}
                </StyledList>
                <SupplierCtaButton
                  label={module.ctaButton.label}
                  module={module}
                  moduleId={moduleId}
                />
              </Body>
            </RightColumn>
          </Container>
        </TrackingContainer>
        {isLastSupplierCtaModule && (
          <FloatingTreatmentButton
            onClick={onJumpLinkClick}
            href={`#${SUPPLIER_CTA_ANCHOR}`}
            show={!isInViewport}
            variant="extended"
            sx={{
              pr: 5.5,
              pl: 5.5,
              bottom: adHeight,
              paddingLeft: '15px',
              paddingRight: '20px',
            }}
          >
            <ArrowDownwardRounded
              sx={{
                mr: 0.5,
                transform:
                  arrowDirection === 'UP' ? 'rotate(180deg)' : undefined,
              }}
            />
            {language.t('SupplierCtaModule.getTreatmentToday')}
          </FloatingTreatmentButton>
        )}
      </div>
    </ScrollableAnchorWrapper>
  );
};

export default SupplierCtaModule;
