import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import Intercom from 'react-intercom';

import { setActiveVideo } from 'state/actions/showcasePageActions';

import ArticleGenericPage from 'components/ArticleGenericPage';
import { PageMeta as Meta } from 'components/Meta';
import ModuleSwitch from 'components/ModuleSwitch';
import StructuredDataSchema from 'components/StructuredDataSchema';
import { PageHero, ErrorPage } from 'styled';
import DisablePageAds from 'styled/components/Ads/DisablePageAds';

import Environments from 'constants/Environments';
import { PageMainContentId } from 'constants/Global';

import {
  ArticleGenericPage as IArticleGenericPage,
  ErrorPage as IErrorPage,
  GenericPage,
} from 'types';
import { GlobalSettingsReducer as GlobalSettings } from 'state/reducers/globalSettings';

interface PassedProps {
  errorPage: IErrorPage;
  page: IArticleGenericPage | GenericPage | null;
  globalSettings?: GlobalSettings;
  disableAds?: boolean;
}

type Props = PassedProps & ReturnType<typeof mapDispatchToProps>;

const VideoPopUp = dynamic(() => import('components/VideoPopUp'), {
  ssr: false,
});

const GenericPageView: FC<Props> = ({
  errorPage,
  page,
  globalSettings,
  actions,
  disableAds = false,
}) => {
  const [videoPopUpIsActive, setVideoPopUpIsActive] = useState(false);

  // Unset scroll lock
  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      document.body.style.overflow = 'initial';
    });
  }, []);

  useEffect(() => {
    if (videoPopUpIsActive) {
      // Set scroll lock
      document.body.style.overflow = 'hidden';
    } else {
      // Unset scroll lock
      document.body.style.overflow = 'unset';
    }
  }, [videoPopUpIsActive]);

  if (!page) return <ErrorPage errorPage={errorPage} />;

  if (page.type === 'articleGenericPage') {
    return <ArticleGenericPage page={page} />;
  }

  if (typeof window !== 'undefined') {
    const currentHost = window.location.host;
    if (
      page.slug === '/style-guide' &&
      !(
        currentHost === Environments.LOCAL.host ||
        currentHost.includes(Environments.STAGING.vercelHost) ||
        currentHost.includes(Environments.STAGING.host)
      )
    ) {
      return <ErrorPage errorPage={errorPage} />;
    }
  }

  const handleOpenPopUp = (
    vimeoId: string,
    youtubeId: string,
    title?: string,
  ) => {
    actions.setActiveVideo(vimeoId, youtubeId, title);
    setVideoPopUpIsActive(true);
  };

  const handleClosePopUp = () => {
    actions.setActiveVideo('', '');
    setVideoPopUpIsActive(false);
  };

  return (
    <div id={PageMainContentId} className="relative">
      <Meta
        title={page.hero.title}
        description={page.hero.description}
        image={page.hero.desktopImage}
        seo={page.seo}
        pathname={page.slug}
      />
      <StructuredDataSchema page={page} />
      <DisablePageAds disabled={page.disableAds || disableAds} />
      {!!page.hero.title && <PageHero hero={page.hero} />}
      <div className="z-2 relative">
        <ModuleSwitch
          modules={page.modules}
          globalSettings={globalSettings}
          setVideoPopUpIsActive={handleOpenPopUp}
          isGenericPage
        />
      </div>
      {page.showIntercom && (
        <Intercom appID={process.env.intercomAppId as string} />
      )}
      <VideoPopUp
        popUpIsActive={videoPopUpIsActive}
        closePopUp={handleClosePopUp}
        pageHasMinimalNav={false}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveVideo,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(GenericPageView);
