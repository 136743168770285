import React, { useRef } from 'react';
import { PortableTextBlock } from '@portabletext/types';
import NextLink from 'next/link';
import sanityImgUtil from 'utils/sanityImgUtil';
import useBreakpoint from 'hooks/useBreakpoint';

import {
  Container,
  TextContainer,
  SimplifiedDescription,
  TitleContainer,
  ImgContainer,
  Cta,
  ExtendedDescription,
} from './style';
import { Base, Img, Link } from 'styled';
import BlockContent from '@sanity/block-content-to-react';
import { Image } from 'types';
import CardActionArea from '@mui/material/CardActionArea';

export interface Props {
  title: string;
  image?: Image;
  imageBgColor?: string;
  description?: string;
  url?: string;
  numberOfCardTilesAtMediumBreakpoint?: number;
  ctaText?: string;
  ctaUrl?: string;
  extendedDescription?: PortableTextBlock;
  isArticle?: boolean;
  medianDescriptionsLength?: number;
  /** Defaults to true */
  bypassNextLink?: boolean;
}

const SMALL_CHARS_PER_DESCRIPTION_LINE = 38;
const MEDIUM_CHARS_PER_DESCRIPTION_LINE_3_COL = 20;
const MEDIUM_CHARS_PER_DESCRIPTION_LINE_2_COL = 40;
const LARGE_CHARS_PER_DESCRIPTION_LINE = 34;
const EXTRA_LARGE_CHARS_PER_DESCRIPTION_LINE = 50;
const EXTRA_EXTRA_LARGE_CHARS_PER_DESCRIPTION_LINE = 56;

const BaseCardTile = (props: Props) => {
  const {
    title,
    image,
    imageBgColor,
    description,
    url,
    numberOfCardTilesAtMediumBreakpoint = 2,
    ctaText,
    ctaUrl,
    extendedDescription,
    isArticle = false,
    medianDescriptionsLength: medianDescriptionsLengthProp = 350,
    bypassNextLink = false,
  } = props;

  // Cap super long descriptions (e.g. for cost articles)
  const medianDescriptionsLength =
    medianDescriptionsLengthProp < 350 ? medianDescriptionsLengthProp : 350;

  const currentBreakpoint = useBreakpoint();

  const getBreakpointBasedCharsPerLine = () => {
    if (!currentBreakpoint) return 100000;
    if (currentBreakpoint === 'LARGE') return LARGE_CHARS_PER_DESCRIPTION_LINE;
    if (currentBreakpoint === 'EXTRA_LARGE')
      return EXTRA_LARGE_CHARS_PER_DESCRIPTION_LINE;
    if (currentBreakpoint === 'EXTRA_EXTRA_LARGE')
      return EXTRA_EXTRA_LARGE_CHARS_PER_DESCRIPTION_LINE;
    if (currentBreakpoint === 'MEDIUM') {
      if (numberOfCardTilesAtMediumBreakpoint === 3) {
        return MEDIUM_CHARS_PER_DESCRIPTION_LINE_3_COL;
      } else {
        return MEDIUM_CHARS_PER_DESCRIPTION_LINE_2_COL;
      }
    }
    return SMALL_CHARS_PER_DESCRIPTION_LINE;
  };

  const calculateDescriptionLines = (medianDescriptionsLength: number) => {
    const charsPerLine = getBreakpointBasedCharsPerLine();
    return Math.ceil(medianDescriptionsLength / charsPerLine);
  };

  const textContainerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLAnchorElement>(null);

  const cardTileHasCta = !!ctaText && !!ctaUrl;

  const medianNumberDescriptionLines = calculateDescriptionLines(
    medianDescriptionsLength,
  );

  return (
    <Container
      data-testid={isArticle ? 'article-card-tile' : 'quiz-card-tile'}
      cardTileHasImage={!!image?.id}
      className="disabled-ads"
    >
      <CardActionArea
        href={url || ''}
        className="h-full"
        LinkComponent={NextLink}
        aria-label={`Navigate to read ${title} article`}
      >
        {!!image?.id && (
          <ImgContainer bgColor={imageBgColor}>
            <Img
              alt={image.alt}
              src={sanityImgUtil(image, 240)}
              dimensions={image.metadata?.dimensions}
              crop={image.crop}
              preloadFullWidth={true}
            />
          </ImgContainer>
        )}
        <TextContainer
          ref={textContainerRef}
          data-testid="text-container"
          overflow="hidden"
        >
          <Base>
            <TitleContainer ref={titleRef} data-testid="title-container">
              <Link
                variant="hover-underline-black"
                label={title}
                ariaLabel=""
                to={undefined} // the whole card is a link
                wrap={true}
                bypassNextLink={bypassNextLink}
              />
            </TitleContainer>

            {description && (
              <SimplifiedDescription $maxLines={medianNumberDescriptionLines}>
                {description}
              </SimplifiedDescription>
            )}

            {isArticle && extendedDescription && (
              <ExtendedDescription>
                <BlockContent blocks={extendedDescription} />
              </ExtendedDescription>
            )}

            {cardTileHasCta && (
              <Cta
                elemRef={ctaRef}
                ariaLabel={ctaText}
                label={ctaText}
                to={ctaUrl}
              />
            )}
          </Base>
        </TextContainer>
      </CardActionArea>
    </Container>
  );
};

export default BaseCardTile;
