import { NextPage } from 'next';
import { reduxWrapper } from 'store';

import { fetchGenericPage } from 'state/actions/genericPageActions';

import GenericPageView from 'views/GenericPageView';

import { RouteMap } from 'constants/Routes';

import {
  ApplicationContext,
  GenericPage,
  ErrorPage as IErrorPage,
} from 'types';
import { initializeApplication } from 'state/actions/applicationActions';
import { REVALIDATE_TIME } from 'constants/Revalidate';

interface InitialProps {
  frontPage: GenericPage | null;
  errorPage: IErrorPage;
}

type Props = InitialProps;

const HomePage: NextPage<Props> = (props) => {
  return (
    <GenericPageView
      page={props.frontPage}
      errorPage={props.errorPage}
      disableAds
    />
  );
};

export const getStaticProps = reduxWrapper.getStaticProps(
  async ({ preview, previewData, store }) => {
    try {
      // _app.tsx#getInitialProps doesn't fire when building static pages. We have to initialize
      // the app here. TODO: think of a DRY'er way to do this, so we don't forget to when we build
      // more pages statically.
      await store.dispatch(
        initializeApplication({
          query: {},
          req: {},
        } as ApplicationContext) as any,
      );

      const frontPageFromStore =
        store.getState().genericPage[RouteMap.HOME.path];

      if (!preview && frontPageFromStore) {
        return {
          props: {
            frontPage: frontPageFromStore,
          },
        };
      }

      const action = fetchGenericPage(
        RouteMap.HOME.path,
        preview ? previewData?.id : '',
      );
      store.dispatch(action);

      return action.payload.then((frontPage) => {
        if (!frontPage) {
          return {
            props: {
              article: null,
            },
            notFound: true,
            revalidate: REVALIDATE_TIME,
          };
        }

        return {
          props: {
            frontPage,
          },
        };
      });
    } catch (e) {
      console.error(e);

      return {
        notFound: true,
      };
    }
  },
);

export default reduxWrapper.withRedux(HomePage);
