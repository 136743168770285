import InterviewConfigurationFactory from './factories/InterviewConfigFactory';
import {
  PXOC_QUESTION_COPY_FLAG_DX,
  PXOC_QUESTION_COPY_FLAG_SX,
  requiredInterviewsMap,
} from './constants';

import type {
  IInterviewConfig,
  IMiniInterviewProps,
  MiniInterviewType,
} from '@buoy-components/polaris/types';
import { ArticleType, FeatureFlags, IInterview } from 'types';
import type { ArticleType as IArticleType } from 'types';
import { Cases, formatTitleCasing } from 'utils/formatTitleCasing';

type BuildAllInterviewConfigsArgs = Pick<
  IMiniInterviewProps,
  'commonCauses' | 'conditionName' | 'slug'
> & {
  articleType: IArticleType | null;
  handleQuestionSubmit: (interview: MiniInterviewType) => void;
  interviews: IInterview[];
  predictors: number[];
};

/*
 * Uses InterviewConfigurationFactory to build all interview configurations for a specific interview.
 * For a symptoms interview, this would just be configuration for the symptoms interview.
 * For a multi-part interview(SxTG) this would be configuration for treatment, treatment guidance, and symptoms
 */
export function buildAllInterviewConfigs(args: BuildAllInterviewConfigsArgs) {
  const {
    articleType,
    commonCauses,
    conditionName,
    handleQuestionSubmit,
    interviews,
    predictors,
    slug,
  } = args;
  const configs: Record<string, IInterviewConfig[]> = {};
  for (const interview of interviews) {
    const interviewType = interview.interview as MiniInterviewType;
    const interviewConfig: IInterviewConfig[] = requiredInterviewsMap[
      interviewType
    ]?.map((i: MiniInterviewType): IInterviewConfig => {
      return InterviewConfigurationFactory.buildInterviewConfiguration({
        articleType,
        interviewType: i,
        interviewProps: {
          commonCauses,
          complaints: predictors,
          conditionName,
          onQuestionSubmit: () => handleQuestionSubmit,
          slug: slug,
          questions: interview.questions,
          formLocation: 'polaris-popup',
          supplierCtaCardConfigs: interview.supplierCtaCardConfigs,
          intro: interview.intro,
          ...(i === 'txoc'
            ? {
                treatment: interview.treatment,
                outcomeCopy: interview.outcomeCopy,
              }
            : {}),
        },
      });
    });
    configs[interview.interview] = interviewConfig;
  }
  return configs;
}

type GetPolarisConfigOptions = {
  configs: Record<string, IInterviewConfig[]>;
  visibleInterviews: IInterview[];
  slug?: string;
  featureFlags: FeatureFlags;
  conditionName?: string;
  articleType?: ArticleType;
  language?: string;
};

/** Adds in additional generic configuration needed for all interviews to run within the Polaris Context */
export function buildPolarisConfiguration({
  configs,
  visibleInterviews,
  slug,
  featureFlags,
  conditionName,
  articleType,
  language,
}: GetPolarisConfigOptions) {
  return visibleInterviews.map((i) => {
    const segmentConfigs = configs[i.interview];
    const parentInterviewId = `${slug}-${i.interview}`;
    return {
      configs: segmentConfigs.map((config) => ({
        ...config,
        // this is the subActor id
        id: `${slug}-${i.interview}-${config.interviewType}`,
        parentInterviewId,
      })),
      question: getAbTestQuestion({
        question: i.question,
        interviewType: i.interview,
        featureFlags,
        conditionName,
        articleType,
        language,
      }),
      interview: i.interview,
      id: parentInterviewId,
    };
  });
}

type ShowPolarisPopupOptions = {
  enabled: boolean | undefined;
  validTreatmentGuidance: boolean;
  shouldFetchPredictors: boolean;
  predictors: number[];
};

export function getAbTestQuestion({
  question,
  interviewType,
  featureFlags,
  articleType,
  conditionName,
  language,
}: {
  question: string;
  interviewType: MiniInterviewType;
  featureFlags: FeatureFlags;
  articleType?: ArticleType;
  conditionName?: string;
  language?: string;
}) {
  if (interviewType !== 'pxoc' || language !== 'en') {
    return question;
  } else if (
    conditionName &&
    articleType === ArticleType.Dx &&
    featureFlags[PXOC_QUESTION_COPY_FLAG_DX]
  ) {
    return featureFlags[PXOC_QUESTION_COPY_FLAG_DX].replaceAll(
      '{condition}',
      formatTitleCasing({ title: conditionName, casing: Cases.LowerCase }),
    );
  } else if (
    conditionName &&
    articleType === ArticleType.Sx &&
    featureFlags[PXOC_QUESTION_COPY_FLAG_SX]
  ) {
    return featureFlags[PXOC_QUESTION_COPY_FLAG_SX].replaceAll(
      '{symptom}',
      formatTitleCasing({ title: conditionName, casing: Cases.LowerCase }),
    );
  }
  return question;
}

export function shouldShowPolarisPopup({
  enabled,
  validTreatmentGuidance,
  shouldFetchPredictors,
  predictors,
}: ShowPolarisPopupOptions) {
  // FF disabled, early exit
  if (!enabled) {
    return false;
  }
  // For TG standalone, show as long as its valid
  if (validTreatmentGuidance) {
    return true;
  }

  // For SxTG/DXOC show only when predictors are available
  if (shouldFetchPredictors && !predictors.length) {
    return false;
  }

  return true;
}

type ShowEmbeddedInterviewOptions = {
  interviewType: MiniInterviewType;
  isValidTxoc: boolean;
  predictors: number[];
  shouldFetchPredictors: boolean;
};
export function shouldShowEmbeddedInterview({
  interviewType,
  isValidTxoc,
  predictors,
  shouldFetchPredictors,
}: ShowEmbeddedInterviewOptions) {
  const missingPredictors = shouldFetchPredictors && !predictors.length;
  const txocIsInvalid = !isValidTxoc && interviewType === 'txoc';
  // Hide if interview needs predictors and there are none
  if (missingPredictors || txocIsInvalid) {
    return false;
  }

  return true;
}

/**
 * On Sx pages, the PxoC interview should appear as the 2nd Mini Interview, beneath Symptom Checker MI.
 */
const getInterviewOrder = (interviewType: MiniInterviewType) => {
  switch (interviewType) {
    case 'symptoms':
      return 1;
    case 'pxoc':
      return 2;
    default:
      return 3;
  }
};

export const sortInterviews = (
  interviews: IInterview[],
  articleType?: ArticleType | null,
) => {
  if (articleType !== ArticleType.Sx) {
    return interviews;
  }

  // Sort interviews array based on order
  return interviews.sort((a, b) => {
    const orderA = getInterviewOrder(a.interview);
    const orderB = getInterviewOrder(b.interview);
    return orderA - orderB;
  });
};
